<template>
    <n-tooltip trigger="hover" placement="top" :show-arrow="false" :class="props?.tooltipClass">
        <template #trigger>
            <condo-icon class="my-0 cursor-pointer" icon="circle-info" icon-pack="fa-light" size="14" />
        </template>
        {{ props.text }}
    </n-tooltip>
</template>

<script setup lang="ts">
import { CondoIcon } from '@condo/ui';

interface Props {
    text: string;
    tooltipClass?: string;
}

const props = defineProps<Props>();
</script>
