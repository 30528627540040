import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import axios, { type AxiosInstance } from 'axios';
import { stringify } from 'qs';

/**
 * Subset of AxiosRequestConfig
 */
export type RequestConfig<TData = unknown> = {
    url?: string;
    method: 'get' | 'put' | 'patch' | 'post' | 'delete';
    params?: unknown;
    data?: TData;
    responseType?: 'arraybuffer' | 'blob' | 'document' | 'json' | 'text' | 'stream';
    signal?: AbortSignal;
    headers?: AxiosRequestConfig['headers'];
};
/**
 * Subset of AxiosResponse
 */
export type ResponseConfig<TData = unknown> = {
    data: TData;
    status: number;
    statusText: string;
    headers?: AxiosResponse['headers'];
};

const axiosInstance = axios.create({
    paramsSerializer: {
        serialize: params => stringify(params, { arrayFormat: 'repeat' }),
    },
});

export function initHTTPClient(
    baseURL: string,
    onRequest?: <T extends AxiosRequestConfig>(config: T) => T | Promise<T>,
    onRejectedResponse?: (error: any) => any,
): AxiosInstance {
    axiosInstance.defaults.baseURL = baseURL;
    if (onRequest) {
        axiosInstance.interceptors.request.use(onRequest);
    }
    if (onRejectedResponse) {
        axiosInstance.interceptors.response.use(response => response, onRejectedResponse);
    }

    return axiosInstance;
}

// this is required for generated API methods
const axiosClient = async <TData, TError = unknown, TVariables = unknown>(config: RequestConfig<TVariables>): Promise<ResponseConfig<TData>> => {
    return axiosInstance.request<TVariables, ResponseConfig<TData>>({ ...config }).catch((e: AxiosError<TError>) => {
        throw e;
    });
};

export default axiosClient;
